import forge from 'node-forge';
import { cardRegex } from './config';
import { errorResponse, successResponse } from './toast';

export const convertHexToRGBA = (hexCode: string, opacity?: number) => {
  let hex = hexCode.replace('#', '');
  let localOpacity = opacity || 1;

  if (hex.length === 3) {
    hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
  }

  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  /* Backward compatibility for whole number based opacity values. */
  if (localOpacity > 1 && localOpacity <= 100) {
    localOpacity /= 100;
  }

  return `rgba(${r},${g},${b},${localOpacity})`;
};

export const getCardType = (number: string) => {
  const cleanedNumber = number.replace(/\D/g, '');

  for (const [cardType, regex] of Object.entries(cardRegex)) {
    if (regex.test(cleanedNumber)) {
      return cardType;
    }
  }

  return 'Unknown';
};

export const validateCardNumber = (cardNumber: string) => {
  // Remove spaces and convert to array of integers
  const nums = cardNumber
    .replace(/\s+/g, '')
    .split('')
    .map((num) => parseInt(num, 10));

  // Check for valid input
  if (!nums.length) {
    return false;
  }

  // Double every second digit from the right and subtract 9 if the result is greater than 9
  for (let i = nums.length - 2; i >= 0; i -= 2) {
    nums[i] = nums[i] * 2;
    if (nums[i] > 9) {
      nums[i] -= 9;
    }
  }

  // Sum all the numbers
  const sum = nums.reduce((total, num) => total + num, 0);

  // The card is valid if the sum is a multiple of 10
  return sum % 10 === 0;
};

export const formatCurrency = (num: number | string, space = true) => {
  const localNumber = typeof num === 'string' ? parseInt(num) : num;

  return Math.sign(localNumber || 0) === -1
    ? `-NGN${space ? ` ` : ''}${localNumber
        .toFixed(2)
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
        .slice(1)}`
    : `NGN${space ? ` ` : ''}${localNumber.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`;
};

export const rsaEncryptData = (data: string, rsaKey: string) => {
  const publicKeyPem = `-----BEGIN PUBLIC KEY-----
  ${rsaKey}
-----END PUBLIC KEY-----`;
  const publicKey = forge.pki.publicKeyFromPem(publicKeyPem);
  const encrypted = publicKey.encrypt(data, 'RSA-OAEP', {
    md: forge.md.sha256.create(),
    mgf1: {
      md: forge.md.sha256.create(),
    },
  });
  const encryptedHex = forge.util.bytesToHex(encrypted);
  return encryptedHex;
};

// aes encrypt card details
function encrypt(data: string, key: string) {
  const iv = forge.random.getBytesSync(16);
  const cipher = forge.cipher.createCipher('AES-CBC', key);

  cipher.start({ iv: iv });
  cipher.update(forge.util.createBuffer(data, 'utf8'));
  cipher.finish();

  return forge.util.bytesToHex(iv + cipher.output.getBytes());
}

export const encryptCardDetails = (val: string) => {
  // Generate random key for AES-256
  const randomEncryptionKey = forge.random.getBytesSync(32); // 32 bytes for AES-256
  // Encrypt the value using the random key
  const firstEncryption = encrypt(val, randomEncryptionKey);
  return {
    key: forge.util.bytesToHex(randomEncryptionKey),
    data: firstEncryption,
  };
};

export const base64EncodeData = (data: string) => {
  // Convert string to byte buffer
  const buffer = forge.util.encodeUtf8(data);
  // Convert byte buffer to base64
  return forge.util.encode64(buffer);
};
export const base64DecodeData = (data: string) => {
  // Decode from base64 to byte buffer
  const buffer = forge.util.decode64(data);
  // Convert byte buffer to UTF-8 string
  return forge.util.decodeUtf8(buffer);
};

export const capitalizeFirstLetter = (val: string) => {
  return val.charAt(0).toUpperCase() + val.slice(1);
};

export const handleCallbackUrl = (url: string, orderId: string, orderReference: string) => {
  const isValid = isValidURL(url);
  if (!isValid) {
    window.location.href = 'https://dashboard.nomba.com';
  } else {
    const callbackUrl = new URL(url);
    callbackUrl.searchParams.set('orderId', orderId.toString());
    callbackUrl.searchParams.set('orderReference', orderReference.toString());
    window.location.href = callbackUrl.toString() || 'https://dashboard.nomba.com';
  }
};

const isValidURL = (url: string) => {
  try {
    new URL(url);
    return true;
  } catch (error) {
    return false;
  }
};

export const filterEmptyValues = (obj: any) => {
  const filteredObj = Object.fromEntries(Object.entries(obj).filter(([, value]) => value !== ''));
  return filteredObj;
};

interface ResponseData {
  status: string | null;
  amount: number | null;
}
interface IBankDetails {
  amount: number;
  account_number: string;
  bank_name: string;
  account_name: string;
}

export const transactionResponse: ResponseData = {
  status: 'success',
  amount: 40000,
};

export const bankAccountDetails: IBankDetails = {
  amount: 35000,
  account_number: '3090008419',
  bank_name: 'Amucha MFB',
  account_name: 'Nomba Terminal Wallet',
};

export type ScreenTypes =
  | 'PaymentMethods'
  | 'BankTransfer'
  | 'BankTransferWait'
  | 'CardInput'
  | 'CardPin'
  | 'CardOtp'
  | 'PhoneOtp'
  | 'VerifyPhone'
  | 'PhoneSuccess'
  | 'SavedCards'
  | '3DSecure'
  | 'SuccessResponse'
  | 'FailedResponse'
  | 'Main'
  | 'TransferDetails'
  | 'AccountExpired'
  | 'ConfirmTransfer'
  | 'GetHelp'
  | 'NombaQR'
  | 'NombaQRFailed'
  | 'USSD'
  | 'USSD2'
  | 'OrderNotFound';

export type SecureParamsType = {
  jwt: string;
  md: string;
  acsUrl: string;
} | null;

export type CardDetailsType = {
  cardCVV: number;
  cardExpiryMonth: number;
  cardExpiryYear: number;
  cardNumber: string;
  cardPin?: number;
};

export type SuccessOrderDetails = {
  orderReference: string;
  orderId: string;
  customerId: string;
  accountId: string;
  callbackUrl: string | null;
  customerEmail: string;
  amount: number;
  currency: string;
  businessName: string | null;
  businessEmail: string | null;
  businessLogo: string | null;
  hasSavedCards?: boolean;
  orderAmount: number;
};

export type FullOrderDetails = {
  hasSavedCards: boolean;
  base64EncodedRsaPublicKey: string | null;
  order: SuccessOrderDetails;
};

export type SavedCard = {
  tokenKey: string;
  cardType: string;
  tokenExpiryDate: string;
  cardPan: string;
  customerEmail: string;
};

export type IPaymentOptions = {
  id: number;
  value: string;
  name: string;
  mobileName: string;
  subText: string | undefined;
  activeIcon: JSX.Element;
  inActiveIcon: JSX.Element;
};

export const QRLogo = 'https://drake.kudi.ai/media/13e7cb23-1312-474f-9d31-929146d5186d';

export const normalizePhoneNumber = (phoneNumber: string) => {
  if (phoneNumber.startsWith('234')) {
    return phoneNumber.replace(/^2340/, '234');
  } else {
    return phoneNumber;
  }
};

export const formatAmount = (amount?: number | string) => {
  return Number(amount).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
};

export const handleCopy = (val: string | number, copyType: string) => {
  try {
    const textArea = document.createElement('textarea');
    textArea.value = String(val);
    document.body.appendChild(textArea);
    textArea.select();

    const successful = document.execCommand('copy');
    document.body.removeChild(textArea);

    if (successful) {
      successResponse(`${copyType} copied successfully!`);
    } else {
      errorResponse(`Failed to copy ${copyType}.`);
    }
  } catch (err) {
    errorResponse(`Error copying ${copyType}.`);
  }
};
