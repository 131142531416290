import { Flex } from 'rebass';
import { CircleLogo } from '../../assets';

export default function Loader() {
  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      width="100%"
      height="100%"
      className="progress-container"
    >
      <CircleLogo />
      <span className="loader"></span>
    </Flex>
  );
}
