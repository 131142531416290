import { useEffect } from 'react';
import { Typography } from 'kudi-component-library';
import { MobilePaymentMethod, PaymentMethodItem } from '../style';
import InstalmentIcon from '../../../assets/instalment';
import KlumpIcon from '../../../assets/klump';
import { Flex } from 'rebass';
import { ChevronRight } from '../../../assets';
import envConfig from '../../../utils/envConfig';

export const KlumpCheckout = ({ onClick, mobile }: { onClick: () => void; mobile?: boolean }) => {
  useEffect(() => {
    try {
      const scriptUrl = String(envConfig.klumpBaseUrl);

      if (!document.querySelector(`script[src="${scriptUrl}"]`)) {
        const script = document.createElement('script');
        script.src = scriptUrl;
        script.async = true;

        document.body.appendChild(script);
      }

      return () => {
        const script = document.querySelector(`script[src="${scriptUrl}"]`);
        if (script) {
          document.body.removeChild(script);
        }
      };
    } catch (e) {
      throw e;
    }
  }, []);

  return (
    <div>
      <div id="klump__checkout" />

      {!mobile ? (
        <PaymentMethodItem active={false} onClick={onClick}>
          <InstalmentIcon />
          <div>
            <Typography fontSize="14px" color="n-grey4" fontWeight={500}>
              Pay in Instalments
            </Typography>{' '}
            <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
              <p style={{ fontSize: '10px', color: '#4D4D4D', fontWeight: '500' }}>Powered by</p>
              <KlumpIcon />
            </div>
          </div>
        </PaymentMethodItem>
      ) : (
        <MobilePaymentMethod onClick={onClick} style={{ borderBottom: '1px solid #EEEEEE' }}>
          <Flex style={{ gap: '10px' }} alignItems="center">
            <KlumpIcon />
            <div>
              <Typography fontSize="14px" color="n-grey4" fontWeight={500}>
                Pay in Instalments
              </Typography>{' '}
              <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                <p style={{ fontSize: '10px', color: '#4D4D4D', fontWeight: '500' }}>Powered by</p>
                <KlumpIcon />
              </div>
            </div>
          </Flex>
          <ChevronRight />
        </MobilePaymentMethod>
      )}
    </div>
  );
};
