import axios from 'axios';
import envConfig from '../utils/envConfig';
const baseUrl = envConfig.baseUrl as string;

export const handleApiCall = async (
  url: string,
  method: string,
  body?: {} | null,
  customHeaders?: { [key: string]: string }
) => {
  const options = {
    method: method,
    headers: {
      'content-type': 'application/json',
      ...customHeaders,
    },
    data: body && body,
    url: `${baseUrl}${url}`,
  };
  const response = await axios(options);
  return response;
};
