import { useEffect, useState } from 'react';
import {
  AccountNumberWrapper,
  BankInfoWrapper,
  CopyBox,
  ClockIconWrapper,
  TransferDetails,
  MobileControl,
  MobileTransferDetails,
} from '../style';
import { Flex, Button, Box } from 'rebass';
import { IconsEnum, NIcons, Typography } from 'kudi-component-library';
import { formatAmount, handleCopy, ScreenTypes } from '../../../utils';
import { ButtonText } from '../../styles';
import FadeIn from 'react-fade-in/lib/FadeIn';
import { KtaOrderResponse, Orderdetails } from '../../../redux/slice';
import { errorResponse, successResponse } from '../../../utils/toast';
import { CountdownTimer } from '../../../utils/Countdown';
import ConfirmTransfer from './ConfirmTransfer';
import GetHelp from '../Responses/GetHelp';
import AccountExpired from '../Responses/AccountExpired';
import ProgressBar from '../../ProgressBar';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../store';
import { confirmBankTransfer } from '../../../redux/action';

interface TransferProps {
  orderDetails: Orderdetails | null;
  ktaResponse: KtaOrderResponse | null;
  changePaymentMethod: () => void;
  onMoveToNewScreen: (newscreen: ScreenTypes, error: string, saveCard: boolean) => void;
  customId?: string;
  setShowCustomModal?: () => void;
}

export default function Transfer({ orderDetails, ktaResponse, changePaymentMethod, onMoveToNewScreen }: TransferProps) {
  const [timer, setTimer] = useState<number>(30);
  const [elapsedTime, setElapsedTime] = useState<number>(1);
  const [currentSubScreen, setCurrentSubScreen] = useState<ScreenTypes>('TransferDetails');

  const dispatch = useDispatch<AppDispatch>();
  const { confirmResponse } = useSelector((state: RootState) => state.order);

  const calculateProgressBarWidth = () => {
    const totalDurationInSeconds = timer * 60;
    const percentageRemaining = ((totalDurationInSeconds - elapsedTime) / totalDurationInSeconds) * 100;
    return percentageRemaining < 1 ? '0%' : `${percentageRemaining}%`;
  };

  const moveToSubScreen = (screen: ScreenTypes) => {
    setCurrentSubScreen(screen);
  };

  useEffect(() => {
    if (elapsedTime === 420 && orderDetails) {
      dispatch(confirmBankTransfer({ orderReference: orderDetails?.data?.order?.orderId }));
    }
  }, [elapsedTime, orderDetails, dispatch]);

  useEffect(() => {
    // retry confirmation of payment every 30 seconds
    const interval = setInterval(() => {
      orderDetails?.data?.order?.orderId &&
        dispatch(confirmBankTransfer({ orderReference: orderDetails?.data?.order?.orderId }));
    }, 30000);
    return () => clearInterval(interval);
  }, [dispatch, orderDetails]);

  useEffect(() => {
    if (confirmResponse === null) {
      return;
    }
    if (confirmResponse?.data?.status) {
      onMoveToNewScreen('SuccessResponse', '', false);
      return;
    }
    if (!confirmResponse?.data?.status && timer === 0) {
      onMoveToNewScreen('FailedResponse', confirmResponse?.data?.message, false);
    }
  }, [confirmResponse, timer, onMoveToNewScreen]);

  return (
    <FadeIn delay={100}>
      {timer !== 0 && currentSubScreen === 'TransferDetails' && (
        <>
          <TransferDetails>
            <Typography color="n-grey4" fontWeight={500}>
              Transfer to the account details below
            </Typography>

            <AccountNumberWrapper>
              <Flex flexDirection="column">
                <Typography fontSize="12px" color="n-grey4">
                  Account Number
                </Typography>
                <Typography fontSize="14px" fontWeight={600}>
                  {ktaResponse?.data?.accountNumber || 'N/A'}
                </Typography>
              </Flex>
              <CopyBox
                onClick={() =>
                  ktaResponse?.data?.accountNumber && handleCopy(ktaResponse?.data?.accountNumber, 'Account Number')
                }
              >
                <Typography fontSize="12px">COPY</Typography>
              </CopyBox>
            </AccountNumberWrapper>

            <BankInfoWrapper bg="#F2F6FF">
              <Flex flexDirection="column">
                <Typography fontSize="12px" color="n-grey4">
                  Bank Name
                </Typography>
                <Typography fontSize="14px" lineHeight="18px" fontWeight={600}>
                  {ktaResponse?.data?.bankName || 'N/A'}
                </Typography>
              </Flex>
              <Flex flexDirection="column">
                <Typography fontSize="12px" color="n-grey4">
                  Account Name
                </Typography>
                <Typography fontSize="14px" lineHeight="18px" fontWeight={600}>
                  {ktaResponse?.data?.accountName || 'N/A'}
                </Typography>
              </Flex>
            </BankInfoWrapper>

            <BankInfoWrapper bg="#F2F6FF">
              <Flex flexDirection="column">
                <Typography fontSize="12px" color="n-grey4">
                  Amount
                </Typography>
                <Typography fontSize="14px" lineHeight="18px" fontWeight={600}>
                  {orderDetails?.data?.order?.currency} {formatAmount(orderDetails?.data?.order?.amount)}
                </Typography>
              </Flex>
            </BankInfoWrapper>

            <Typography style={{ marginTop: '10px' }} color="n-grey4" fontSize="14px" fontWeight={500}>
              Use this account only for this transaction
            </Typography>
            <Flex justifyContent="center" alignItems="center" width="50%" style={{ gap: '10px' }}>
              <Typography style={{ whiteSpace: 'nowrap' }} color="n-grey4" fontSize="12px" fontWeight={500}>
                Expires in
              </Typography>
              <CountdownTimer
                {...{
                  minutes: timer,
                  seconds: 0,
                  cb: () => setTimer(0),
                  setElapsedTime: setElapsedTime,
                  color: '#0E9C00',
                }}
              />
              <ClockIconWrapper>
                <NIcons icon={IconsEnum.OUTLINE_CLOCK} width="100%" height="100%" />
              </ClockIconWrapper>
              <ProgressBar width={timer === 0 ? '0%' : calculateProgressBarWidth()} />
            </Flex>

            <Button
              onClick={() => moveToSubScreen('ConfirmTransfer')}
              textAlign="center"
              mt="20px"
              bg="#FFCC00"
              color="#121212"
              variant="large"
              width="100%"
              height="50px"
              className="cursor-pointer"
            >
              <ButtonText>I have sent the money</ButtonText>
            </Button>

            <MobileControl>
              <Button
                bg="#FFFFFF"
                color="#121212"
                variant="large"
                width="100%"
                height="50px"
                className="cursor-pointer custom-btn"
              >
                <ButtonText>Change payment method</ButtonText>
              </Button>
            </MobileControl>
          </TransferDetails>

          <MobileTransferDetails>
            <Typography color="n-grey4" fontWeight={500}>
              Transfer to the account details below
            </Typography>

            <AccountNumberWrapper>
              <Flex flexDirection="column">
                <Typography fontSize="12px" color="n-grey4">
                  Account Number
                </Typography>
                <Typography fontSize="14px" fontWeight={600}>
                  {ktaResponse?.data?.accountNumber || 'N/A'}
                </Typography>
              </Flex>
              <CopyBox
                onClick={() =>
                  ktaResponse?.data?.accountNumber && handleCopy(ktaResponse?.data?.accountNumber, 'Account Number')
                }
              >
                <Typography fontSize="12px">COPY</Typography>
              </CopyBox>
            </AccountNumberWrapper>

            <BankInfoWrapper bg="#F2F6FF">
              <Flex flexDirection="column">
                <Typography fontSize="12px" color="n-grey4">
                  Bank Name
                </Typography>
                <Typography fontSize="14px" lineHeight="18px" fontWeight={600}>
                  {ktaResponse?.data?.bankName || 'N/A'}
                </Typography>
              </Flex>
              <Flex flexDirection="column">
                <Typography fontSize="12px" color="n-grey4">
                  Account Name
                </Typography>
                <Typography fontSize="14px" lineHeight="18px" fontWeight={600}>
                  {ktaResponse?.data?.accountName || 'N/A'}
                </Typography>
              </Flex>
            </BankInfoWrapper>

            <BankInfoWrapper bg="#F2F6FF">
              <Flex flexDirection="column">
                <Typography fontSize="12px" color="n-grey4">
                  Amount
                </Typography>
                <Typography fontSize="14px" lineHeight="18px" fontWeight={600}>
                  {orderDetails?.data?.order?.currency} {formatAmount(orderDetails?.data?.order?.amount)}
                </Typography>
              </Flex>
            </BankInfoWrapper>

            <Typography
              style={{ marginTop: '30px', textAlign: 'center' }}
              color="n-grey4"
              fontSize="14px"
              fontWeight={500}
            >
              Use this account only for this transaction
            </Typography>

            <Box display="flex" justifyContent="center" alignItems="center">
              <Box display="flex" justifyContent="center" alignItems="center" width="50%" style={{ gap: '10px' }}>
                <Typography
                  style={{ whiteSpace: 'nowrap', textAlign: 'center' }}
                  color="n-grey4"
                  fontSize="12px"
                  fontWeight={500}
                >
                  Expires in
                </Typography>
                <CountdownTimer
                  {...{
                    minutes: timer,
                    seconds: 0,
                    cb: () => setTimer(0),
                    setElapsedTime: setElapsedTime,
                    color: '#0E9C00',
                  }}
                />
                <ClockIconWrapper>
                  <NIcons icon={IconsEnum.OUTLINE_CLOCK} width="100%" height="100%" />
                </ClockIconWrapper>
                <ProgressBar width={timer === 0 ? '0%' : calculateProgressBarWidth()} />
              </Box>
            </Box>

            <Button
              onClick={() => moveToSubScreen('ConfirmTransfer')}
              textAlign="center"
              mt="20px"
              bg="#FFCC00"
              color="#121212"
              variant="large"
              width="100%"
              height="60px"
              className="cursor-pointer"
            >
              <ButtonText>I have sent the money</ButtonText>
            </Button>

            <MobileControl onClick={() => changePaymentMethod()} style={{ marginTop: '20px' }}>
              <Button
                bg="#FFFFFF"
                color="#121212"
                variant="large"
                width="100%"
                height="60px"
                className="cursor-pointer change-btn"
              >
                <ButtonText>Change payment method</ButtonText>
              </Button>
            </MobileControl>
          </MobileTransferDetails>
        </>
      )}
      {timer === 0 && <AccountExpired setTimer={setTimer} setCurrentSubScreen={setCurrentSubScreen} />}

      {currentSubScreen === 'ConfirmTransfer' && (
        <ConfirmTransfer
          changePaymentMethod={changePaymentMethod}
          setCurrentSubScreen={setCurrentSubScreen}
          onMoveToNewScreen={onMoveToNewScreen}
          orderReference={orderDetails?.data?.order?.orderId}
        />
      )}
      {currentSubScreen === 'GetHelp' && (
        <GetHelp orderDetails={orderDetails} setCurrentSubScreen={setCurrentSubScreen} />
      )}
    </FadeIn>
  );
}
