/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

declare global {
  interface Window {
    _env_: {
      REACT_APP_CHECKOUT_VERSION: string;
      REACT_APP_BASE_URL: string;
      REACT_APP_KLUMP_BASE_URL: string;
      REACT_APP_KLUMP_PUBLIC_KEY: string;
    };
  }
}

interface AppConfig {
  checkoutVersion: string;
  baseUrl: string;
  klumpBaseUrl: string;
  klumpPublicKey: string;
}

const envConfig: AppConfig = {
  checkoutVersion: window._env_.REACT_APP_CHECKOUT_VERSION,
  baseUrl: window._env_.REACT_APP_BASE_URL,
  klumpBaseUrl: window._env_.REACT_APP_KLUMP_BASE_URL,
  klumpPublicKey: window._env_.REACT_APP_KLUMP_PUBLIC_KEY,
};

export default envConfig;
