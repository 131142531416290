import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Flex } from 'rebass';
import { OtpInput, Typography } from 'kudi-component-library';
import { useDispatch, useSelector } from 'react-redux';
import { MobileCardDetails, TransferDetails } from '../style';
import { CardDetailsType, SecureParamsType, ScreenTypes } from '../../../utils';
import { CheckoutRequest, createOrder } from '../../../redux/action';
import { AppDispatch, RootState } from '../../../store';
import Loader from '../../Loader';
import SecureAuth from '../../SecureAuth';
import PinLockIcon from '../../../assets/pin-lock';

export default function CardPin({
  setOtpMessage,
  setTransactionId,
  cardDetails,
  orderReference,
  saveCard,
  setCurrentSubScreen,
  onMoveToNewScreen,
  setActive,
}: {
  onMoveToNewScreen: (newscreen: ScreenTypes, error: string, saveCard: boolean) => void;
  setOtpMessage: React.Dispatch<React.SetStateAction<string>>;
  setTransactionId: React.Dispatch<React.SetStateAction<string>>;
  setCurrentSubScreen: React.Dispatch<React.SetStateAction<ScreenTypes>>;
  saveCard: boolean;
  cardDetails: CardDetailsType;
  orderReference: string;
  setActive: React.Dispatch<React.SetStateAction<number>>;
}) {
  const [pinValue, setPinValue] = useState<string>('');
  const [pinError, setPinError] = useState<string>('');
  const [secureParams, setSecureParams] = useState<SecureParamsType>(null);

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const { inProgress, createOrderResponse } = useSelector((state: RootState) => state.order);

  function handleChange(val: string) {
    setPinValue(val);
    setPinError('');
    if (val.length === 4) {
      handleSubmit(val);
    }
  }

  const handleSubmit = async (val: string) => {
    cardDetails.cardPin = Number(val);
    // JSON stringify cardDetails
    const jsonCardDetails = JSON.stringify(cardDetails);
    const finalPayload: CheckoutRequest = {
      cardDetails: jsonCardDetails,
      key: '',
      orderReference: orderReference,
      saveCard: saveCard,
      deviceInformation: {
        httpBrowserLanguage: navigator.language,
        httpBrowserJavaEnabled: 'true',
        httpBrowserJavaScriptEnabled: 'true',
        httpBrowserColorDepth: window.screen.colorDepth.toString(),
        httpBrowserScreenHeight: window.screen.height.toString(),
        httpBrowserScreenWidth: window.screen.width.toString(),
        httpBrowserTimeDifference: new Date().getTimezoneOffset().toString(),
        userAgentBrowserValue: navigator.userAgent,
        deviceChannel: 'Browser',
      },
    };

    dispatch(createOrder(finalPayload));
  };

  useEffect(() => {
    if (createOrderResponse === null) {
      return;
    }

    if (
      createOrderResponse &&
      createOrderResponse?.data?.responseCode === 'S0' &&
      createOrderResponse?.data?.secureAuthenticationData?.jwt &&
      createOrderResponse?.data?.secureAuthenticationData?.md &&
      createOrderResponse?.data?.secureAuthenticationData?.acsUrl
    ) {
      const params: SecureParamsType = {
        jwt: createOrderResponse?.data?.secureAuthenticationData?.jwt,
        md: createOrderResponse?.data?.secureAuthenticationData?.md,
        acsUrl: createOrderResponse?.data?.secureAuthenticationData?.acsUrl,
      };
      setSecureParams(params);
    } else if (createOrderResponse && createOrderResponse?.data?.responseCode === 'T0') {
      createOrderResponse?.data?.transactionId && setTransactionId(createOrderResponse?.data?.transactionId);
      setOtpMessage(createOrderResponse?.data?.message);
      setCurrentSubScreen('CardOtp');
    } else if (createOrderResponse && createOrderResponse?.data?.responseCode === '00') {
      onMoveToNewScreen('SuccessResponse', '', saveCard);
    } else if (
      createOrderResponse &&
      createOrderResponse?.data?.responseCode === 'M0' &&
      createOrderResponse?.data?.secureAuthenticationData?.authPage
    ) {
      const authPage = createOrderResponse?.data?.secureAuthenticationData?.authPage;

      if (authPage) {
        const container = document.getElementById('payment-container');

        if (container) {
          container.style.display = 'none';
          container.innerHTML = authPage;
          const form = document.querySelector('form') as HTMLFormElement | null;

          if (form) {
            form.target = '_self';
            form.submit();
          }
        }
      }
    } else if (
      createOrderResponse &&
      createOrderResponse?.data?.responseCode === 'F0' &&
      createOrderResponse?.data?.secureAuthenticationData?.termUrl
    ) {
      window.open(createOrderResponse?.data?.secureAuthenticationData?.termUrl, '_self');
    } else {
      setPinError(createOrderResponse?.data?.message || createOrderResponse?.description);
    }
  }, [
    createOrderResponse,
    saveCard,
    navigate,
    setOtpMessage,
    setCurrentSubScreen,
    onMoveToNewScreen,
    setTransactionId,
  ]);

  const payWithBankTransfer = () => {
    setActive(1);
  };

  return (
    <>
      <TransferDetails style={{ marginTop: '30px', textAlign: 'center' }}>
        {inProgress ? (
          <Flex flexDirection="column" style={{ gap: '10px' }}>
            <Loader />
            <Typography color="n-grey4" fontWeight={500}>
              Please wait
            </Typography>
          </Flex>
        ) : (
          <>
            {secureParams !== null ? (
              <SecureAuth params={secureParams} />
            ) : (
              <>
                <Typography color="n-dark" fontWeight={500}>
                  Please enter your 4-digit card PIN to <br />
                  complete transaction
                </Typography>

                <Box mt="40px">
                  <OtpInput
                    width="100%"
                    inputsNumber={4}
                    name="token"
                    value={pinValue}
                    onChange={(e) => handleChange(e.target.value)}
                    error={pinError}
                  />
                </Box>

                {pinError ? (
                  <div style={{ marginTop: '13px', border: '1px solid #eeeeee', padding: '10px', borderRadius: '3px' }}>
                    <Typography
                      onClick={() => payWithBankTransfer()}
                      className="cursor-pointer"
                      color="n-grey4"
                      fontWeight={500}
                    >
                      Pay with Bank Transfer
                    </Typography>
                  </div>
                ) : null}
              </>
            )}
          </>
        )}
      </TransferDetails>

      <MobileCardDetails style={{ height: '50vh', alignItems: 'center', textAlign: 'center' }}>
        {inProgress ? (
          <Flex flexDirection="column" alignItems="center" justifyContent="center" style={{ gap: '10px' }}>
            <Loader />
            <Typography color="n-grey4" fontWeight={500}>
              Please wait
            </Typography>
          </Flex>
        ) : (
          <>
            {secureParams !== null ? (
              <SecureAuth params={secureParams} />
            ) : (
              <>
                <PinLockIcon />
                <Typography color="n-dark" fontWeight={500} style={{ marginTop: '30px' }}>
                  Please enter your 4-digit card PIN to <br />
                  complete transaction
                </Typography>

                <Box mt="40px">
                  <OtpInput
                    width="100%"
                    inputsNumber={4}
                    name="token"
                    value={pinValue}
                    onChange={(e) => handleChange(e.target.value)}
                    error={pinError}
                  />
                </Box>

                {pinError ? (
                  <div style={{ marginTop: '13px', border: '1px solid #eeeeee', padding: '10px', borderRadius: '3px' }}>
                    <Typography
                      onClick={() => payWithBankTransfer()}
                      className="cursor-pointer"
                      color="n-grey4"
                      fontWeight={500}
                    >
                      Pay with Bank Transfer
                    </Typography>
                  </div>
                ) : null}
              </>
            )}
          </>
        )}
      </MobileCardDetails>
      <div id="payment-container" />
    </>
  );
}
